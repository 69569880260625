<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Crie comprovantes de entrega de vasilhames para abatimento direto no caixa. Além disso, vincule vasilhames a produtos com garrafas retornáveis.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-sm-7 mb-3" :class="dadosUsuario.usuarioPermissoes.includes('Automação | Emitir comprovante vasilhame') ? 'col-md-5 col-xl-8' : 'col-md-9 col-xl-10'">
							<label><i class="far fa-wine-bottle color-theme font-11 me-1"></i> Nome</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarVasilhames" placeholder="Busque por nome do vasilhame" />
                  </div>
						<div class="col-sm-5 col-md-3 col-xl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarVasilhames"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Editar vasilhames')">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                        </div>
                     </div>
						</div>
						<div class="col-12 col-md-4 col-xl-2 mb-3 align-self-end" v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Emitir comprovante vasilhame')">
							<button type="button" class="btn btn-primary w-100" @click="abrirModalComprovante"><i class="far fa-share font-12 me-1"></i> Emitir comprovante</button>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<combo v-for="(combo, index) in pesquisa.retorno.resultado" :key="index" :combo="combo" :index="index" 
							@editar="abrirModal($event)" @buscarComprovantes="buscarComprovantes($event, false)" />
					</div>
				</div>
			</div>
      </div>

		<!-- modalVasilhame -->
      <div class="modal fade" id="modalVasilhame" tabindex="-1" aria-labelledby="modalVasilhameLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalVasilhameLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} vasilhame {{ modal.id == null ? '' : ('('+ modal.nome +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-principal-tab" data-bs-toggle="pill" data-bs-target="#pills-principal" type="button" role="tab" aria-controls="pills-principal" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-md-inline"> Principal</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-produto-tab" data-bs-toggle="pill" data-bs-target="#pills-produto" type="button" role="tab" aria-controls="pills-produto" aria-selected="false">
											<i class="far fa-carrot font-12"></i><span class="ms-1 d-none d-md-inline"> Produto retornável</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-vasilhame-tab" data-bs-toggle="pill" data-bs-target="#pills-vasilhame" type="button" role="tab" aria-controls="pills-vasilhame" aria-selected="false">
											<i class="far fa-wine-bottle font-12"></i><span class="ms-1 d-none d-md-inline"> Vasilhame</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-principal" role="tabpanel" aria-labelledby="pills-principal-tab">
								<div class="card mb-2">
									<div class="card-body p-12">
										<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Nome *</label>
										<input type="text" class="form-control" v-model="modal.nome" maxlength="200" />
										<div class="alert alert-secondary alert-dismissible fade show shadow-none mb-0 pe-3 mt-2" role="alert">
											<strong>Vasilhame</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
											<span>Defina o produto com garrafa retornável para o vasilhame ser incluído no ato da compra. Exemplo: A cada galão de água 20 litros bipado, uma garrafa vazia será adicionada na compra.</span>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-produto" role="tabpanel" aria-labelledby="pills-produto-tab">
								<div class="card">
									<div class="card-body p-12">
										<div class="col-12 mb-3">
											<h6 class="mb-0 mt-1 font-15">
												<span class="mb-0 font-14 weight-400">{{ modal.produtos.length }} produto encontrado</span>
												<a href="javascript:;" class="float-end font-16" @click="toggleAddProduto('produto')"><i class="fas fa-plus color-theme"></i></a>
											</h6>
										</div>
										
										<produto v-for="(produto, index) in modal.produtos" :key="index" :produto="produto" :index="index" :campos="{}" @remover="removerProduto($event, 'produto')" />
										
										<div class="col-12 my-5 text-center" v-if="modal.produtos.length == 0">
											<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
											<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-vasilhame" role="tabpanel" aria-labelledby="pills-vasilhame-tab">
								<div class="card">
									<div class="card-body p-12">
										<div class="col-12 mb-3">
											<h6 class="mb-0 mt-1 font-15">
												<span class="mb-0 font-14 weight-400">{{ modal.brinde ? 1 : 0 }} vasilhame encontrado</span>
												<a href="javascript:;" class="float-end font-16" @click="toggleAddProduto('vasilhame')"><i class="fas fa-plus color-theme"></i></a>
											</h6>
										</div>

										<div class="row" v-if="modal.brinde">
											<produto :produto="modal.brinde" :campos="{'selectEAN': true}" @remover="removerProduto($event, 'vasilhame')" />
										</div>
										
										<div class="col-12 my-5 text-center" v-else>
											<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
											<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarVasilhame">Salvar</button>
						<button type="button" class="btn btn-outline-primary" v-if="modal.id" @click="imprimir">Imprimir</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>

		<!-- modalComprovante -->
      <div class="modal fade" id="modalComprovante" tabindex="-1" aria-labelledby="modalComprovanteLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalComprovanteLabel">Emitir comprovante de vasilhames</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="row">
							<div class="col-12 px-0">
								<div class="card mb-2">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-sm-6 mb-3 mb-sm-0">
												<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
												<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="comprovante.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Selecione uma opção">
													<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control" v-model="comprovante.loja">
													<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
												</select>
											</div>
											<div class="col-sm-6">
												<label><i class="far fa-wine-bottle color-theme font-10 me-1"></i> Adicionar vasilhame</label>
												<input v-if="!comprovante.toggleAutocomplete" value="Aguarde..." type="text" class="form-control" readonly>
												<autocomplete v-else ref="autocomplete" :search="buscarCombos" :debounce-time="1000" @submit="addProduto($event)" :submitOnEnter="true" :get-result-value="r => r.brinde.nome" placeholder="Buscar por nome do vasilhame">
													<template #result="{ result, props }">
														<li v-bind="props">
															<h1 class="font-14 mb-1 limitador-1 text-capitalize">{{ String(result.brinde.nome).toLowerCase() }}</h1>
															<p class="font-10 mb-0 limitador-1 opacity-90"><span><i class="far fa-barcode font-9 me-1"></i>{{ result.brinde.ean }}</span></p>
														</li>
													</template>
												</autocomplete>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<produto v-for="(produto, index) in comprovante.itens" :key="index" :produto="produto" :index="index" :campos="{}" @remover="removerProduto($event, 'comprovante')" />
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="emitirComprovante">Emitir</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>

		<!-- modalComprovantes -->
      <div class="modal fade" id="modalComprovantes" tabindex="-1" aria-labelledby="modalComprovantesLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalComprovantesLabel">Comprovantes - {{ comprovantes.nomeVasilhame }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body" v-if="comprovantes.resultado.length > 0">
                  <div class="cards-150 cards-sm-200">
                     <comprovante v-for="(cupom, index) in comprovantes.resultado" :key="index" :index="index" :cupom="cupom" />
                  </div>

                  <div class="w-100 mt-3 mb-2 text-center" v-if="comprovantes.resultado.length % 30 == 0">
                     <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarComprovantes(null, false)">Ver mais</button>
                  </div>
               </div>
               <div class="modal-body my-5 text-center" v-else>
                  <i class="fal fa-print-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
                  <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum comprovante encontrado</h4>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" v-if="comprovantes.resultado.length > 0" @click="buscarComprovantes(null, true)">Exportar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<modalProdutoPreco :modalAnterior="'#modalVasilhame'" @addProduto="addProduto($event)" />

		<impressaoVasilhame id="comprovante" class="d-none" :comprovante="comprovante.resultado" v-if="comprovante.resultado != null" />
		<impressao id="impressao" class="d-none" :titulo="$route.name.replace(/_/g, ' ').replace(/-/g, '/')" :data="new Date()" :cabecalho="{
			'Nome': modal.nome == null ? ' - ' : modal.nome, 
			'Vasilhame - Produto': modal.brinde == null ? ' - ' : modal.brinde.nome, 
			'Vasilhame - Quantidade': modal.brinde == null ? ' - ' : parseFloat(modal.brinde.quantidade).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:4}), 
			'Vasilhame - Valor': modal.brinde == null ? ' - ' : parseFloat(modal.brinde.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:4})
			}" :itensKey="{'nome': 'Descrição', 'quantidade': 'Quantidade'}" :itens="modal.produtos" />
	</div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import impressao from '@/components/impressao/ComprovanteA4.vue'
import combo from '@/components/automacao/combosPacks/Combo.vue'
import produto from '@/components/automacao/combosPacks/Produto.vue'
import comprovante from '@/components/automacao/vasilhames/Comprovante.vue'
import modalProdutoPreco from '@/components/produtos/ModalProdutoPreco.vue'
import impressaoVasilhame from '@/components/impressao/ComprovanteVasilhame.vue'

export default {
	name: 'Vasilhames',
	data: function () {
		return {
			pesquisa: {'loja': null, 'nome': '', 'isVasilhame': true, 'retorno': {'loja': null, 'nome': null, 'resultado': []}},
			modal: {'id': null, 'nome': null, 'isVasilhame': true, 'lojas': [], 'brinde': null, 'produtos': [], 'produto': {'toggle': false, 'tipo': null}},
			comprovante: {'loja': null, 'itens': [], 'resultado': null, 'toggleAutocomplete': true},
			comprovantes: {'idVasilhame': null, 'nomeVasilhame': null, 'pagina': 0, 'resultado': []},
         listaExportacao: []
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		combo, produto, impressao, modalProdutoPreco, impressaoVasilhame, comprovante
	},
	methods: {
		imprimir : function () {
         setTimeout(() => this.$htmlToPaper('impressao', {styles: ['./comprovanteA4.css']}), 250);
      },
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Combos',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'nome', 'isVasilhame'], 
				'lista': null
			})
      },
		abrirModal : function (vasilhame) {
			this.modal = {
				'id': null, 'nome': null, 'isVasilhame': this.pesquisa.isVasilhame, 'lojas': [], 'brinde': null, 'produtos': [], 'produto': {'toggle': false, 'tipo': null}
			}

			if (vasilhame != null) {
				this.buscarVasilhame(vasilhame)
			} else {
				$('#modalVasilhame').modal('show')
			}
		},
		abrirModalComprovante : function () {
			this.modal.produto.tipo = 'comprovante'
			// this.comprovante = {'loja': null, 'itens': [], 'resultado': null, 'toggleAutocomplete': true}

			$('#modalComprovante').modal('show')
		},
		buscarVasilhames : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.buscarCombos(this.pesquisa.nome, true).then(data => {
				this.pesquisa.retorno = data
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			})
		},
		buscarCombos : function (input, fullResult) {
			/* eslint-disable-next-line no-unused-vars */
			return new Promise((resolve, reject) => {
				if (!fullResult && input.length < 3) resolve([])

				return this.$axios({
					method: 'post',
					url: this.urlRest +'automacao/searchCombos',
					headers: {'Content-Type': 'application/json'},
					data: {'loja': null, 'nome': input, 'isVasilhame': true}

				}).then(response => {
					resolve(fullResult ? response.data : response.data.resultado)

				}).catch((error) => {
					if (error.response != undefined) {
						if (error.response.status == 408) {
							this.$store.dispatch('deslogar')

							this.$toast.fire({
								icon: 'warning',
								title: 'Sessão expirada!'
							});
						} else {
							this.$toast.fire({
								icon: 'error',
								title: 'Erro: ' + error.response.status
							});
						}
					}

					resolve([])
				})
			})
      },
		buscarVasilhame : function (combo) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'automacao/getCombo',
				params: {
					id: combo.id
				}
			}).then(response => {
				$('#modalVasilhame').modal('show')

				this.modal = {
					'id': response.data.id, 
					'nome': response.data.nome, 
					'isVasilhame': response.data.isVasilhame,
					'lojas': [],
					'brinde': response.data.brinde,
					'produtos': JSON.parse(JSON.stringify(response.data.produtos)),
					'produto': {'toggle': false, 'tipo': null}
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		toggleAddProduto : function (tipo) {
			this.modal.produto = {'toggle': !this.modal.produto.toggle, 'tipo': tipo}
			$('#modalVasilhame').modal('hide')
			$('#modalProdutoPreco').modal('show')
		},
		addProduto : function (produto) {
			if (this.modal.produto.tipo == 'comprovante') {
				this.comprovante.itens.unshift(produto.brinde)
				this.comprovante.toggleAutocomplete = false;
				setTimeout(() => this.comprovante.toggleAutocomplete = true, 50);
				setTimeout(() => this.$refs.autocomplete.$refs.input.focus(), 100);
				
			} else if (this.modal.produto.tipo == 'vasilhame') {
				this.modal.brinde = JSON.parse(JSON.stringify(produto))

			} else {
				if (this.modal.produtos.length != 0) {
					this.modal.produtos[0] = JSON.parse(JSON.stringify(produto))
				} else {
					this.modal.produtos.push(JSON.parse(JSON.stringify(produto)))
				}
			}
		},
		removerProduto : function (index, tipo) {
			if (tipo == 'comprovante') {
				this.comprovante.itens.splice(index, 1)
			} else if (tipo == 'vasilhame') {
				this.modal.brinde = null
			} else {
				this.modal.produtos.splice(index, 1)
			}
		},
		salvarVasilhame : function () {
			if (this.modal.nome == null || String(this.modal.nome).trim().length == 0 || this.modal.brinde == null || this.modal.produtos.length == 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			} else if (this.modal.produtos.filter(p => !/^[0-9.]+$/.test(p.quantidade) || parseFloat(p.quantidade) < 1).length > 0 ||
				!/^[0-9.]+$/.test(this.modal.brinde.quantidade) || !/^[0-9.]+$/.test(this.modal.brinde.preco) || 
				parseFloat(this.modal.brinde.quantidade) < 1 || parseFloat(this.modal.brinde.preco) < 0.01) {
				this.$toast.fire({
					icon: 'error',
					title: 'Existem produtos com quantidade/preços inválidos!'
				});
	
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'automacao/saveCombo',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalVasilhame').modal('hide')
				this.buscarVasilhames()

				this.$toast.fire({
					icon: 'success',
					title: 'Vasilhame salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		emitirComprovante : function () {
			if (this.comprovante.loja == null || this.comprovante.itens.length == 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			} else if (this.comprovante.itens.filter(p => !/^[0-9.]+$/.test(p.quantidade) || parseFloat(p.quantidade) < 1).length > 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Existem itens com quantidade inválida!'
				});
	
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'automacao/emitirComprovanteVasilhame',
				headers: {'Content-Type': 'application/json'},
				data: this.comprovante

			}).then((response) => {
				this.comprovante = {'loja': null, 'itens': [], 'resultado': response.data, 'toggleAutocomplete': true}
				$('#modalComprovante').modal('hide')

				Swal.fire({
					icon: 'success',
					title: 'Comprovante de vasilhame gerado!',
					text: 'Imprimir via?',
					showCancelButton: true,
					confirmButtonText: 'Sim',
					cancelButtonText: `Não`
					
				}).then((result) => {
					if (result.isConfirmed) {
						setTimeout(() => this.$htmlToPaper('comprovante', {styles: ['./bootstrap.print.css']}), 250);
					}
				})
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarComprovantes : function (objVasilhame, isExcel) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.comprovantes = {
				'idVasilhame': objVasilhame != null ? objVasilhame.id : this.comprovantes.idVasilhame, 
				'nomeVasilhame': objVasilhame != null ? objVasilhame.nome : this.comprovantes.nomeVasilhame, 
				'pagina': isExcel ? -1 : (objVasilhame != null ? 0 : this.comprovantes.pagina), 
				'resultado': objVasilhame != null ? [] : this.comprovantes.resultado
			}

			$('#modalComprovantes').modal('show')
         Object.assign(this.$data.listaExportacao, this.$options.data.apply(this).listaExportacao)

         this.$axios({
            method: 'post',
            url: this.urlRest +'automacao/searchComprovantesVasilhame',
				headers: {'Content-Type': 'application/json'},
				data: this.comprovantes

         }).then(response => {
				if (isExcel) {
               this.listaExportacao = response.data
            } else {
               this.comprovantes.pagina++;
               this.comprovantes.resultado.push(... response.data)
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)

            if (isExcel) {
               this.$store.dispatch('exportarExcel', {
                  'nome': 'Comprovantes',
                  'resultado': this.listaExportacao, 
                  'campos': ['id', 'codigo', 'data', 'usuario', 'quantidadeItens'], 
                  'lista': null
               })
            }
         });
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>