<template>
   <div class="card mb-0" ref="comprovante" :index="index">
      <div class="card-img text-center overflow-hidden">
         <img src="@/assets/images/comprovante.png" alt="icon" class="p-1">
      </div>
      <div class="card-body text-center p-2">
         <h5 class="limitador-1">
            <span class="font-15"><small>Cód.</small> {{ cupom.codigo }}</span>
         </h5>
         <p class="mb-0 d-none d-sm-block">
            <span class="limitador-1">
               <i class="far fa-user-check color-theme font-10 me-1"></i><span> {{ cupom.usuario == null ? 'Desconhecido' : cupom.usuario }}</span>
            </span>
         </p>
         <p class="mb-0 d-none d-lg-block">
            <span class="limitador-1">
               <i class="far fa-box color-theme font-10 me-1"></i><span> {{ cupom.quantidadeItens }} vasilhame(s)</span>
            </span>
         </p>
         <p class="limitador-1 mb-1">
            <i class="far fa-calendar color-theme font-10 me-1"></i><span> {{ cupom.data == null ? 'Sem data' : cupom.data }}</span>
         </p>

         <button class="btn btn-white invert" @click="getComprovanteVasilhame"><i class="fal fa-print font-11 me-1"></i> Imprimir</button>
      </div>

      <impressaoVasilhame :id="'vasilhame'+ index" class="d-none" :comprovante="comprovante" v-if="comprovante != null" />
   </div>
</template>

<script>

import { mapState } from 'vuex'
import impressaoVasilhame from '@/components/impressao/ComprovanteVasilhame.vue'

export default {
	name: 'Comprovante',
	props: ['cupom', 'index'],
   data: function () {
      return {
         comprovante: null
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         tokenSessao: state => state.tokenSessao,
         urlRest: state => state.urlRest
      })
   },
   components: {
      impressaoVasilhame
   },
   methods: {
      getComprovanteVasilhame : function () {
         this.$store.dispatch('alternarTelaCarregamento', true);
         this.comprovante = null;

         this.$axios({
            method: 'get',
            url: this.urlRest +'automacao/getComprovanteVasilhame',
            params: {
               id: this.cupom.id
            }
         }).then(response => {
            this.comprovante = response.data;
            setTimeout(() => this.$htmlToPaper('vasilhame'+ this.index, {styles: ['./bootstrap.print.css']}), 250);

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar');

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false);
         });
      }
   }
}

</script>

<style scoped>

h5 {
   font-size: 14px;
   margin-bottom: 8px;
   font-weight: 500;
}

.card[index] img {
	aspect-ratio: 1/1;
	object-fit: cover;
   max-width: 100px;
   margin: 10px 0;
   transition: all .3s ease-out 0s;
}

.card[index]:hover img {
   transform: scale(1.1) rotate(1deg);
}

p span {
   font-size: 12px;
   text-transform: capitalize;
}

button {
   margin-top: 10px;
   transition: all 0.5s;
   font-size: 12px;
}

button:hover {
   background-color: var(--color-theme);
   border-color: var(--color-theme);
   color: #fff;
   border-radius: 3px;
}

</style>